import React from "react";
import { graphql,Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { getPrettyDate } from "../utils/dates";
import Pagination from "../components/newComponents/pagination";

export const query = graphql`
  query PageEntriesQuery($limit: Int, $skip: Int) {
    posts: allCraftPagePageEntry(sort: {fields: postDate, order: DESC}, limit: $limit, skip: $skip) {
      nodes {
        title
        displayTitle
        slug
        uri
        postDate
        featureImage {
          ... AssetFragment
        }
      }
    }
  }
`;

const News = ({data: { posts }, pageContext}) => {
  return (
    <Layout pageContext={pageContext}>
      <Seo title="Hello, World" />
      <h1 className="text-4xl font-black font-display mb-5 md:mt-11">
        <Link to="/pages">Page Index</Link>
      </h1>
        <div>
          {posts.nodes.map((post, i) => (  
            <article key={i} className={`relative`}>
              <div className="box box--secondary box--orange" >
                <div className="box__inner">   
                  <div className="box__body">
                    <p className="box__title h6">{post.displayTitle}</p>
                    <p>{getPrettyDate(post.postDate)}</p>
                    <p className="box__subtitle">
                      <div dangerouslySetInnerHTML={{ __html: post.summary }}/>
                    </p>
                    <Link to={`/${post.uri}`} className="box__link link-more text-gray-3">Read more</Link>
                  </div>
                </div>
              </div>
            </article>
          ))}
        </div>
        {pageContext.totalPages > 1 && 
          <Pagination nextUrl={pageContext.nextUrl} prevUrl={pageContext.prevUrl} currentPage={pageContext.currentPage} base="/pages" totalPages={pageContext.totalPages}/>
        }
    </Layout>
  )
};

export default News;